import React from "react";
import "./index.css"

const Footer = () => {
    return(
        <div className="footer">
            <p>Created by Pedro Moore.</p>
        </div>
    )
}

export default Footer;